/* Importing Oxygen font */
/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Mulish:wght@400;500;700&display=swap");

/* Importing tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
